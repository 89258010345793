import BigNumber from 'bignumber.js'

export const ERROR = 'ERROR'
export const RESET_PROFILE = 'RESET_PROFILE'

// #Accounts
export const CONNECTION_CONNECTED = 'CONNECTION_CONNECTED'
export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED'

export const GET_BALANCES = 'GET_BALANCES'
export const BALANCES_RETURNED = 'BALANCES_RETURNED'

export const GET_BALANCES_LIGHT = 'GET_BALANCES_LIGHT'
export const BALANCES_LIGHT_RETURNED = 'BALANCES_LIGHT_RETURNED'

export const GET_VAULT_BALANCES_FULL = 'GET_VAULT_BALANCES_FULL'
export const VAULT_BALANCES_FULL_RETURNED = 'VAULT_BALANCES_FULL_RETURNED'

export const GET_VAULT_BALANCES = 'GET_VAULT_BALANCES'
export const VAULT_BALANCES_RETURNED = 'VAULT_BALANCES_RETURNED'

export const GET_EXPERIMENTAL_VAULT_BALANCES_FULL = 'GET_EXPERIMENTAL_VAULT_BALANCES_FULL'
export const EXPERIMENTAL_VAULT_BALANCES_FULL_RETURNED = 'EXPERIMENTAL_VAULT_BALANCES_FULL_RETURNED'

export const GET_EXPERIMENTAL_VAULT_BALANCES = 'GET_EXPERIMENTAL_VAULT_BALANCES'
export const EXPERIMENTAL_VAULT_BALANCES_RETURNED = 'EXPERIMENTAL_VAULT_BALANCES_RETURNED'


// #Dashboard
export const GET_DASHBOARD_SNAPSHOT = 'GET_DASHBOARD_SNAPSHOT'
export const DASHBOARD_SNAPSHOT_RETURNED = 'DASHBOARD_SNAPSHOT_RETURNED'

export const GET_USD_PRICE = 'GET_USD_PRICE'
export const USD_PRICE_RETURNED = 'USD_PRICE_RETURNED'


// #Earn
export const INVEST = 'INVEST'
export const INVEST_RETURNED = 'INVEST_RETURNED'

export const INVEST_ALL = 'INVEST_ALL'
export const INVEST_ALL_RETURNED = 'INVEST_ALL_RETURNED'

export const REDEEM = 'REDEEM'
export const REDEEM_RETURNED = 'REDEEM_RETURNED'


// #Vaults
export const DEPOSIT_VAULT = 'DEPOSIT_VAULT'
export const DEPOSIT_VAULT_RETURNED = 'DEPOSIT_VAULT_RETURNED'

export const DEPOSIT_ALL_VAULT = 'DEPOSIT_ALL_VAULT'
export const DEPOSIT_ALL_VAULT_RETURNED = 'DEPOSIT_ALL_VAULT_RETURNED'

export const WITHDRAW_VAULT = 'WITHDRAW_VAULT'
export const WITHDRAW_VAULT_RETURNED = 'WITHDRAW_VAULT_RETURNED'

export const WITHDRAW_ALL_VAULT = 'WITHDRAW_ALL_VAULT'
export const WITHDRAW_ALL_VAULT_RETURNED = 'WITHDRAW_ALL_VAULT_RETURNED'


// #experimental
export const DEPOSIT_EXPERIMENTAL_VAULT = 'DEPOSIT_EXPERIMENTAL_VAULT'
export const DEPOSIT_EXPERIMENTAL_VAULT_RETURNED = 'DEPOSIT_EXPERIMENTAL_VAULT_RETURNED'

export const DEPOSIT_ALL_EXPERIMENTAL_VAULT = 'DEPOSIT_ALL_EXPERIMENTAL_VAULT'
export const DEPOSIT_ALL_EXPERIMENTAL_VAULT_RETURNED = 'DEPOSIT_ALL_EXPERIMENTAL_VAULT_RETURNED'

export const CLAIM_EXPERIMENTAL_VAULT = 'CLAIM_EXPERIMENTAL_VAULT'
export const CLAIM_EXPERIMENTAL_VAULT_RETURNED = 'CLAIM_EXPERIMENTAL_VAULT_RETURNED'

export const ZAP_EXPERIMENTAL_VAULT = 'ZAP_EXPERIMENTAL_VAULT'
export const ZAP_EXPERIMENTAL_VAULT_RETURNED = 'ZAP_EXPERIMENTAL_VAULT_RETURNED'

// #Zap
export const ZAP = 'ZAP'
export const ZAP_RETURNED = 'ZAP_RETURNED'

export const SWAP = 'SWAP'
export const SWAP_RETURNED = 'SWAP_RETURNED'

export const TRADE = 'TRADE'
export const TRADE_RETURNED = 'TRADE_RETURNED'

export const GET_CURV_BALANCE = 'GET_CURV_BALANCE'
export const GET_CURV_BALANCE_RETURNED = 'GET_CURV_BALANCE_RETURNED'

export const GET_BEST_PRICE = 'GET_BEST_PRICE'
export const GET_BEST_PRICE_RETURNED = 'GET_BEST_PRICE_RETURNED'


// #APR
export const GET_AGGREGATED_YIELD = 'GET_AGGREGATED_YIELD'
export const GET_AGGREGATED_YIELD_RETURNED = 'GET_AGGREGATED_YIELD_RETURNED'


// #Manage
export const DONATE = 'DONATE'
export const DONATE_RETURNED = 'DONATE_RETURNED'

export const REBALANCE = 'REBALANCE'
export const REBALANCE_RETURNED = 'REBALANCE_RETURNED'

export const GET_CONTRACT_EVENTS = 'GET_CONTRACT_EVENTS'
export const GET_CONTRACT_EVENTS_RETURNED = 'GET_CONTRACT_EVENTS_RETURNED'

export const IDAI = 'IDAI'
export const IDAI_RETURNED = 'IDAI_RETURNED'


export const GET_STATISTICS = 'GET_STATISTICS'
export const STATISTICS_RETURNED = 'STATISTICS_RETURNED'


// #LENDING
export const CONFIGURE_LENDING = 'CONFIGURE_LENDING'
export const CONFIGURE_LENDING_RETURNED = 'CONFIGURE_LENDING_RETURNED'

export const GET_LENDING_BALANCES = 'GET_LENDING_BALANCES'
export const LENDING_BALANCES_RETURNED = 'LENDING_BALANCES_RETURNED'

export const LENDING_SUPPLY = 'LENDING_SUPPLY'
export const LENDING_SUPPLY_RETURNED = 'LENDING_SUPPLY_RETURNED'

export const LENDING_WITHDRAW = 'LENDING_WITHDRAW'
export const LENDING_WITHDRAW_RETURNED = 'LENDING_WITHDRAW_RETURNED'

export const LENDING_BORROW = 'LENDING_BORROW'
export const LENDING_BORROW_RETURNED = 'LENDING_BORROW_RETURNED'

export const LENDING_REPAY = 'LENDING_REPAY'
export const LENDING_REPAY_RETURNED = 'LENDING_REPAY_RETURNED'

export const LENDING_ENABLE_COLLATERAL = 'LENDING_ENABLE_COLLATERAL'
export const LENDING_ENABLE_COLLATERAL_RETURNED = 'LENDING_ENABLE_COLLATERAL_RETURNED'

export const LENDING_DISABLE_COLLATERAL = 'LENDING_DISABLE_COLLATERAL'
export const LENDING_DISABLE_COLLATERAL_RETURNED = 'LENDING_DISABLE_COLLATERAL_RETURNED'


// #COVER
export const CONFIGURE_COVER = 'CONFIGURE_COVER'
export const CONFIGURE_COVER_RETURNED = 'CONFIGURE_COVER_RETURNED'

export const GET_COVER_BALANCES = 'GET_COVER_BALANCES'
export const COVER_BALANCES_RETURNED = 'COVER_BALANCES_RETURNED'

export const COVER_PURCHASE = 'COVER_PURCHASE'
export const COVER_PURCHASE_RETURNED = 'COVER_PURCHASE_RETURNED'

export const COVER_CLAIM = 'COVER_CLAIM'
export const COVER_CLAIM_RETURNED = 'COVER_CLAIM_RETURNED'

export const COVER_REDEEM = 'COVER_REDEEM'
export const COVER_REDEEM_RETURNED = 'COVER_REDEEM_RETURNED'


// #GENERAL
export const MAX_UINT256 = new BigNumber(2)
  .pow(256)
  .minus(1)
  .toFixed(0);
